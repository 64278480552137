import Vue from 'vue'
import Vuex from 'vuex'
import storage from './storage.js'

function getBaseUrl() {
  let url =  window.location.origin
  if (url.includes('localhost')) url = 'https://a.rte.im'
  if (url.includes('192.168.')) url = 'https://a.rte.im'
  return url
}

function getCabinetUrl() {
   let currentUrl = window.location.origin
  if (currentUrl.includes("localhost") || currentUrl.includes("a4.rte.im")) {
    return "https://c4.rte.im";
  }else  return "https://c.rte.im";
}

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isMinimizeMenu: false,
    showMobileMenu: false,
    showMobileHeader: false,
    user: {
      isLogin: localStorage.getItem('isLoginRTE') === 'true',
      first_name: 'FirstName',
      last_name: 'LastName',
      id: localStorage.getItem('userId') == null ? null : localStorage.getItem('userId'),
      email: localStorage.getItem('userEmailRTE') == null ? null : localStorage.getItem('userEmailRTE'),
      role: {
        id: localStorage.getItem('userRoleIdRTE') == null ? -1 : localStorage.getItem('userRoleIdRTE'),
        role: localStorage.getItem('userRoleRTE') == null ? "guest" : localStorage.getItem('userRoleRTE'),
        name: localStorage.getItem('userRoleNameRTE') == null ? "Guest" : localStorage.getItem('userRoleNameRTE')
      },
      zones: null,
      user_hash: null,
      uuid: null,
      poster_custom_setting: null
    },
    menuData: {
      newEventCount: 0
    },
    curLanguage: {
      locale: localStorage.getItem('curLanguageLocale') == null ? 'ru_ru' : localStorage.getItem('curLanguageLocale'),
      img: localStorage.getItem('curLanguageImg') == null ? '/assets/media/flags/glob.png' : localStorage.getItem('curLanguageImg')
    },
    url: getBaseUrl(),
    cabinetUrl: getCabinetUrl(),
    showBackDrop: false,
    credentials: {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('authorizationRTEAccessToken'),
        "Accept-Language": getHeaderLanguage(localStorage.getItem('curLanguageLocale') === null ? 'ru' : localStorage.getItem('curLanguageLocale'))
      }
    },
    layout: 'default-layout',
		referrer: localStorage.getItem('referrer_url') || null,
    buttonSpinnerId: '',
		...storage.state
  },
  mutations: {
    setLanguage(state, payload) {
      state.curLanguage.locale = payload.lang
      state.curLanguage.img = payload.img
      state.credentials.headers['Accept-Language'] = getHeaderLanguage(payload.lang)
      localStorage.setItem('curLanguageLocale', state.curLanguage.locale)
      localStorage.setItem('curLanguageImg', state.curLanguage.img)
    },
    setLayout(state, payload) {
      state.layout = payload
    },
    setMenuData(state, payload){
      state.menuData.newEventCount = payload.newEventCount
    },
    toggleAside(state){
      state.isMinimizeMenu = !state.isMinimizeMenu
    },
    toggleMobileHeader(state){
      state.showMobileHeader = !state.showMobileHeader
    },
    toggleMobileAside(state){
      state.showMobileMenu = !state.showMobileMenu
    },
    hideMobileHeader(state){
      state.showMobileHeader = false;
    },
    hideMobileAside(state){
      state.showMobileMenu = false;
    },
    setUserData(state, data) {
      data.isLogin = true
      state.user = data
    },
    logInSuccess(state, data){
      state.credentials.headers['Authorization'] = 'Bearer ' + data.access_token

      localStorage.setItem('authorizationRTEAccessToken', data.access_token)
      localStorage.setItem('userRoleIdRTE', data.role.id)
      localStorage.setItem('userRoleRTE', data.role.role)
      localStorage.setItem('userRoleNameRTE', data.role.name)
      localStorage.setItem('userEmailRTE', data.email)
      localStorage.setItem('userId', data.id)
      localStorage.setItem('registrarZones', JSON.stringify(data.zones))
      localStorage.setItem('isLoginRTE', 'true')

      let currentDate = new Date()
      let clearTime = currentDate.setHours(currentDate.getHours() + 23)
      localStorage.setItem('clearTimeRTE', clearTime.toString())

      state.user.isLogin = true;
      state.user.role = data.role
      state.user.email = data.email
      state.user.id = data.id
      state.user.zones = data.zones
      if (data.user_hash) state.user.user_hash = data.user_hash
      if (data.first_name) state.user.first_name = data.first_name
      if (data.last_name) state.user.last_name = data.last_name
      if (data.uuid) state.user.uuid = data.uuid
      if (data.poster_custom_setting) state.uesr.poster_custom_setting = data.poster_custom_setting
    },
    logOut(state){
      state.user.isLogin = false;
      localStorage.setItem('isLoginRTE', 'false')
      localStorage.removeItem('authorizationRTEAccessToken')
      sessionStorage.clear()
      sessionStorage.setItem('redirect', window.location.href)
    },
    showBackDrop(state){
      state.showBackDrop = true
    },
    hideBackDrop(state){
      state.showBackDrop = false
    },
		setReferrer(state, payload) {
			localStorage.setItem('referrer_url', payload)
			state.referrer = payload
		},
    setSpinner(state, payload) {
      state.buttonSpinnerId = payload
    },
		...storage.mutations
  },
  actions: {
    setLanguage(context, response){
      context.commit('setLanguage', response)
    },
    setMenuData(context, response){
      context.commit('setMenuData', response)
    },
    setUserData(context, response) {
      context.commit('setUserData', response)
    },
    toggleAside(context) {
      context.commit('toggleAside')
    },
    toggleMobileHeader(context) {
      context.commit('toggleMobileHeader')
    },
    logInSuccess(context, response) {
      context.commit('logInSuccess', response)
    },
    logOut(context) {
      context.commit('logOut')
    },
    hideMobileHeader(context) {
      context.commit('hideMobileHeader')
    },
    toggleMobileAside(context) {
      context.commit('toggleMobileAside')
    },
    hideMobileAside(context) {
      context.commit('hideMobileAside')
    },
    showBackDrop(context) {
      context.commit('showBackDrop')
    },
    hideBackDrop(context) {
      context.commit('hideBackDrop')
    },
    setLayout(context, response) {
      context.commit('setLayout', response)
    },
		setReferrer(context, response) {
			context.commit('setReferrer', response)
		},
		...storage.actions
  },
  getters: {
    user(state) {
      return state.user
    },
    layout(state) {
      return state.layout
    },
    menuData(state) {
      return state.menuData
    },
    curLanguage(state) {
      return state.curLanguage
    },
    sideMenu(state) {
      return state.isMinimizeMenu
    },
    url(state) {
      return state.url
    },
    cabinetUrl(state) {
      return state.cabinetUrl
    },
		...storage.getters
  }
})

function getHeaderLanguage(lang) {
  if(lang === 'ru_ru') {
    return 'ru'
  } else if(lang === 'en_us') {
    return 'en'
  } else if(lang === 'uk_ua') {
    return 'ua'
  }
  return 'ru'
}
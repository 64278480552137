import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import guest from './middleware/guest'
import auth from './middleware/auth'
import administratorOrganizer from "./middleware/administratorOrganizer";
import administrator from './middleware/administrator'
import registrar from './middleware/registrar'
import partner from './middleware/partner'
import sales from './middleware/sales'

import middlewarePipeline from './middlewarePipeline'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    // component: () => import("../views/Home"),
    component: () =>
      store.getters.user.email == "testorg@rte.im"
        ? import("../views/Home(new)")
        : import("../views/Home"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/registration",
    name: "Registration",
    component: () => import("../views/auth/Reg"),
    meta: {
      layout: "auth-layout",
      middleware: [guest],
    },
  },
  {
    path: "/regnewevent",
    name: "NewCreateEvent",
    component: () => import("../views/events/NewCreateEvent"),
    meta: {
      layout: "auth-layout",
      middleware: [guest],
    },
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import("../views/auth/Authorization"),
    meta: {
      layout: "auth-layout",
      middleware: [guest],
    },
  },
  {
    path: "/forgot-password",
    name: "forgotPass",
    component: () => import("../views/auth/ForgotPass"),
    meta: {
      layout: "auth-layout",
      middleware: [guest],
    },
  },
  {
    path: "/reset/:token",
    name: "resetPass",
    component: () => import("../views/auth/ResetPassword"),
    meta: {
      layout: "auth-layout",
    },
  },
  {
    path: "/verify/:emailToken",
    name: "verifyEmail",
    component: () => import("../views/auth/VerifyPhone"),
    meta: {
      layout: "auth-layout",
      middleware: [guest],
    },
  },
  {
    path: "/verify/in/:emailToken",
    name: "verifyInEmail",
    component: () => import("../views/auth/VerifyPhone1"),
    meta: {
      layout: "auth-layout",
      middleware: [guest],
    },
  },
  //verify phone
  // {
  //   path: '/verify-phone',
  //   name: 'verifyPhone',
  //   component: () => import('../views/auth/VerifyPhone'),
  //   meta: {
  //     layout: 'auth-layout',
  //     middleware: [
  //       guest
  //     ]
  //   }
  // },

  // Events
  {
    path: "/events",
    name: "events",
    component: () => import("../views/events/Events"),
    meta: {
      middleware: [auth, partner],
    },
  },

  {
    path: "/create-event",
    name: "createEvent",
    component: () => import("../views/events/CreateEvent"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  {
    path: "/event/:eventId/copy-event",
    name: "copyEvent",
    component: () => import("../views/events/CopyEvent"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  {
    path: "/fast-create-event/:eventId",
    name: "fastCreateEvent",
    component: () => import("../views/events/fastCreateEvent/FastCreateEvent"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  {
    path: "/events/:eventId/orders/:orderId",
    name: "orderDetails",
    component: () => import("../views/orders/OrderDetails"),
    meta: {
      middleware: [auth, administratorOrganizer, sales],
    },
  },

  {
    path: "/event/:eventId/edit",
    name: "updateEvent",
    component: () => import("../views/events/UpdateEvent"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  {
    path: "/event/:eventId",
    name: "eventDetails",
    component: () => import("../views/events/EventDetails"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  {
    path: "/event/:eventId/tickets",
    name: "ticketSchemas",
    component: () => import("../views/events/ticketSchemas/TicketSchemas"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  {
    path: "/docs/google-calendar",
    name: "ticketSchemas",
    component: () => import("../views/docs/GoogleCalendar"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  {
    path: "/event/:eventId/reg-forms",
    name: "regForms",
    component: () => import("../views/events/regForms/RegForms"),
    meta: {
      middleware: [auth, partner],
    },
  },
  {
    path: "/event/:eventId/integration-links",
    name: "integraionLinks",
    component: () =>
      import("../views/events/integrationLinks/IntegrationLinks"),
    meta: {
      middleware: [auth, partner],
    },
  },

  {
    path: "/event/:eventId/payment-systems",
    name: "PaymentSystems",
    component: () => import("../views/events/paymentSystems/PaymentSystems"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/event/:eventId/payments",
    name: "PaymentSystems",
    component: () => import("../views/events/paymentSystems/Payments.vue"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  {
    path: "/event/:eventId/event-settings",
    name: "Settings",
    component: () => import("../views/events/settings/Settings"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  {
    path: "/event/:eventId/promocodes",
    name: "Promocodes",
    component: () => import("../views/events/promocodes/Promocodes"),
    meta: {
      middleware: [auth, partner],
    },
  },

  {
    path: "/event/:eventId/streams",
    name: "Streams",
    component: () => import("../views/events/streams/Streams"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  {
    path: "/event/:eventId/landing",
    name: "Landing",
    component: () => import("../views/events/landing/Landing"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/event/:eventId/landing2",
    name: "Landing",
    component: () => import("../views/events/landing/Landing2"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  {
    path: "/event/:eventId/mobile-app",
    name: "Mobile",
    component: () => import("../views/events/mobile/Mobile(new)"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
    children: [
      {
        path: "dashboard",
        component: () => import("../components/events/mobile/Dashboard"),
      },
      {
        path: "users",
        component: () => import("../components/events/mobile/Users"),
      },
      {
        path: "feed",
        component: () => import("../components/events/mobile/Feed"),
      },
      {
        path: "complaints",
        component: () => import("../components/events/mobile/feed/Complaints"),
      },
      {
        path: "bans",
        component: () => import("../components/events/mobile/feed/Bans"),
      },
      {
        path: "push",
        component: () => import("../components/events/mobile/Push"),
      },
      {
        path: "settings",
        component: () => import("../components/events/mobile/userList"),
      },
      {
        path: "speakers",
        component: () => import("../components/events/mobile/Speakers"),
      },
      {
        path: "agenda",
        component: () => import("../components/events/mobile/Program"),
      },
      {
        path: "halls",
        component: () => import("../components/events/mobile/Halls"),
      },
      {
        path: "partners",
        name: "Partner-mobile",
        component: () => import("../components/events/mobile/Partners"),
      },
      {
        path: "polls",
        name: "Polls",
        component: () => import("../components/events/mobile/Polls"),
      },
      {
        path: "qr-quest",
        name: "QR quest",
        component: () => import("../components/events/mobile/QRQuest"),
      },
      {
        path: "event-survey",
        name: "Event survey",
        component: () => import("../components/events/mobile/EventSurvey"),
      },
      {
        path: "companies",
        name: "Companies",
        component: () => import("../components/events/mobile/Companies"),
      },
    ],
  },

  {
    path: "/event/:eventId/telegram-bot",
    name: "Mobile",
    component: () => import("../views/events/telegram/Telegram"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  // Orders
  {
    path: "/orders",
    name: "orders",
    component: () => import("../views/orders/Orders"),
    meta: {
      middleware: [auth, partner, sales],
    },
  },
  {
    path: "/create-order",
    name: "createOrder",
    component: () => import("../views/orders/CreateOrder"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/orders/kanban",
    name: "Kanban",
    component: () => import("../views/kanban/Kanban.vue"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  // users
  {
    path: "/users",
    name: "Users",
    component: () => import("../views/users/Users"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  {
    path: "/users/:userId/edit",
    name: "EditUser",
    component: () => import("../views/users/EditUser"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  {
    path: "/create-user",
    name: "CreateUser",
    component: () => import("../views/users/CreateUser"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  // Profile
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/profile/Profile"),
    children: [
      {
        path: "overview",
        name: "overview",
        component: () => import("../views/profile/Overview"),
        meta: {
          middleware: [auth, administratorOrganizer],
        },
      },
      {
        path: "personal-information",
        name: "personalInformation",
        component: () => import("../views/profile/PersonalInfo"),
        meta: {
          middleware: [auth, administratorOrganizer],
        },
      },
      {
        path: "change-password",
        name: "changePassword",
        component: () => import("../views/profile/ChangePassword"),
        meta: {
          middleware: [auth, administratorOrganizer],
        },
      },
      {
        path: "payments",
        name: "payments",
        component: () => import("../views/profile/Payments.vue"),
        meta: {
          middleware: [auth, administratorOrganizer],
        },
      },
      {
        path: "rates",
        name: "rates",
        component: () => import("../views/profile/Rates"),
        meta: {
          middleware: [auth, administratorOrganizer],
        },
      },
    ],
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  // Commission
  {
    path: "/comission",
    name: "Comission",
    component: () => import("../views/commission/Comission"),
    meta: {
      middleware: [auth, administrator, sales],
    },
  },
  {
    path: "/exceptions",
    name: "Exceptions",
    component: () => import("../views/exceptions/Exceptions"),
    meta: {
      middleware: [auth, administrator, sales],
    },
  },
  {
    path: "/help",
    name: "Help",
    component: () => import("../views/help/HelpCenter"),
    meta: {
      middleware: [auth, administrator, sales],
    },
  },
  {
    path: "/emails",
    name: "Email",
    component: () => import("../views/emails/Emails"),
    meta: {
      middleware: [auth, administrator],
    },
  },
  {
    path: "/advices",
    name: "Advices",
    component: () => import("../views/advices/Advices"),
    meta: {
      middleware: [auth, administrator, sales],
    },
  },
  {
    path: "/export",
    name: "ExportImport",
    component: () => import("../views/exportImport/ExportImport"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  // checkin
  {
    path: "/checkin",
    name: "CheckinRegistration",
    component: () => import("../views/checkin/CheckinRegistration"),
    meta: {
      middleware: [auth, registrar],
    },
  },
  {
    path: "/checkin-qr",
    name: "CheckinRegistrationQrScan",
    component: () => import("../views/checkin/QRScanPage"),
    meta: {
      middleware: [auth, registrar],
    },
  },
  {
    path: "/checkin-statistic",
    name: "CheckinStatistic",
    // component: () => import("../views/checkin/CheckinStatistic"),
    component: () => import("../views/checkin/CheckinStatistic2"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/zones",
    name: "CheckinZones",
    component: () => import("../views/checkin/zones/CheckinZones"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/zones/add",
    name: "CreateCheckinZones",
    component: () => import("../views/checkin/zones/CreateCheckinZone"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/zones/:eventId/edit/:zoneId",
    name: "UpdateCheckinZones",
    component: () => import("../views/checkin/zones/UpdateCheckinZone"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/event/:eventId/orders/:orderId/checkin/checkin-card/:ticketId/zone/:zoneId",
    name: "CheckinZoneCard",
    component: () => import("../views/checkin/zones/CheckinZoneCard"),
    meta: {
      middleware: [auth, registrar],
    },
  },
  {
    path: "/sticker-settings",
    name: "Stickers",
    component: () => import("../views/checkin/stickerSettings/Stickers"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/sticker-settings/create",
    name: "CreateStickerSettings",
    component: () =>
      import("../views/checkin/stickerSettings/CreateStickerSettings"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/sticker-settings/:eventId",
    name: "UpdateStickerSettings",
    component: () =>
      import("../views/checkin/stickerSettings/UpdateStickerSettings"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/print-settings",
    name: "Printer",
    component: () => import("../views/checkin/PrintSettings"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/print-settings/create",
    name: "CreatePrinterSettings",
    component: () => import("@/views/checkin/printer/CreatePrintSettings.vue"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/print-settings/edit/:printerId",
    name: "UpdatePrinterSettings",
    component: () => import("@/views/checkin/printer/CreatePrintSettings.vue"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/event/:eventId/orders/:orderId/checkin/checkin-card/:ticketId",
    name: "CheckinCard",
    component: () => import("../views/checkin/CheckinCard"),
    meta: {
      middleware: [auth, registrar],
    },
  },
  {
    path: "/staff-group",
    name: "StaffGroup",
    component: () => import("../views/checkin/staff/StaffGroup.vue"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/staff-group/add",
    name: "AddStaffGroup",
    component: () => import("../views/checkin/staff/AddStaffGroup.vue"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/staff-group/:eventId/:groupHash",
    name: "StaffGroupPage",
    component: () => import("../views/checkin/staff/StaffGroupPage.vue"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/staff-group/:eventId/edit/:groupHash",
    name: "EditStaffGroup",
    component: () => import("../views/checkin/staff/EditStaffGroup.vue"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/staff-group/:eventId/:groupHash/add-worker",
    name: "AddStaffGroupWorker",
    component: () => import("../views/checkin/staff/AddStaff.vue"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/staff-group/:eventId/:groupHash/edit-worker/:staffId",
    name: "EditStaffGroupWorker",
    component: () => import("../views/checkin/staff/EditStaff.vue"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/event/:eventId/staff-group/:groupHash/staff/:staffHash",
    name: "CheckinStaff",
    component: () => import("../views/checkin/staff/CheckinStaff.vue"),
    meta: {
      middleware: [auth, administratorOrganizer, registrar],
    },
  },

  // currency
  {
    path: "/currency-rate",
    name: "Currency",
    component: () => import("../views/currency/Currency"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  //
  {
    path: "/no-access",
    name: "noAccess",
    component: () => import("../views/servicePages/NoAccess"),
  },

  {
    path: "*",
    name: "NotFound",
    component: () => import("../views/servicePages/NotFound"),
  },

  // Analytics
  {
    path: "/analytics-traffic",
    name: "analytics-traffic",
    component: () => import("../views/analytics/AnalyticsTraffic"),
    meta: {
      middleware: [auth, administrator],
      //[auth, partner, sales],
    },
  },
  {
    path: "/analytics-payments",
    name: "analytics-payments",
    component: () => import("../views/analytics/AnalyticsPayments"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  // Finances
  {
    path: "/finances",
    name: "finances",
    component: () => import("../views/finances/Finances"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/finances2",
    name: "finances",
    component: () => import("../views/finances/Finances2"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  // Networking
  {
    path: "/networking",
    name: "networking",
    component: () => import("../views/networking/Networking"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  // Mail Push
  {
    path: "/mail-push",
    name: "mail-push",
    component: () => import("../views/mail-push/Mailpush"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  {
    path: "/mail-push/:eventId/:mode/create",
    name: "mail-push-create",
    component: () => import("../views/mail-push/MailpushCreate"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  {
    path: "/mail-push/:eventId/:mode/edit/:pushId",
    name: "mail-push-edit",
    component: () => import("../views/mail-push/MailpushEdit"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  {
    path: "/mail-push-history",
    name: "mail-push-history",
    component: () => import("../views/mail-push/MailPushHistory"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  // Chat bot
  {
    path: "/chat-bot-telegram",
    name: "chat-bot-telegram",
    component: () => import("../views/chat-bot/MenuChatBotTg"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  {
    path: "/chat-bot-telegram/:eventId",
    name: "telegramCodes",
    params: true,
    component: () => import("../views/chat-bot/telegramCodes/TelegramCodes"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  {
    path: "/chat-bot-facebook",
    name: "chat-bot-facebook",
    component: () => import("../views/chat-bot/MenuChatBotFb"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/bot-telegram",
    name: "bot-telegram",
    component: () => import("../views/menu-bot/BotTelegram.vue"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/bot-sales",
    name: "bot-sales",
    component: () => import("../views/menu-bot/BotSales.vue"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  // trigger messages
  {
    path: "/trigger-messages",
    name: "trigger-messages",
    component: () => import("../views/trigger-messages/TriggerMessages"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  {
    path: "/trigger-messages/add",
    name: "add-trigger-messages",
    component: () => import("../views/trigger-messages/AddTriggerMessages"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
  {
    path: "/trigger-messages/:eventId/edit/:triggerId",
    name: "edit-trigger-messages",
    component: () => import("../views/trigger-messages/EditTriggerMessages"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  // bot menu
  {
    path: "/bot-menu-user",
    name: "bot-menu-user",
    component: () => import("../views/menu-bot/MenuBotUser"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  //balance
  {
    path: "/balance",
    name: "balance",
    component: () => import("../views/balance/Balance"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },

  // poster
  {
    path: "/poster",
    name: "Poster",
    component: () => import("../views/poster/Poster"),
    meta: {
      middleware: [auth, administratorOrganizer],
    },
  },
];

const router = new VueRouter({
  mode: 'hash',
  routes
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware
  const context = {
    to,
    from,
    next,
    store
  }
  return middleware[0]({
      ...context,
      nextMiddleware: middlewarePipeline(context, middleware, 1)
  })
})


export default router